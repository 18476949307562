<template>
  <div>
    
    <v-container class="background" fluid fill-height>
      <v-layout wrap>
        <v-flex xs12 sm6 offset-sm3 style="padding-top:10%">
          <v-card elevation="5" light tag="section">
            <v-card-title>
              <!-- <v-layout align-center justify-space-between>
                <h3 class="headline font-weight-bold"> 
                  Sistem Pakar Pertanian
                </h3>
                <v-flex>
                  <v-img class="ml-3" contain height="48px" position="center right" src="@/assets/img/mardi-logo.png"></v-img>
                </v-flex>
              </v-layout> -->
              <v-layout align-center justify-space-between>
                <v-spacer></v-spacer>
                <h3 class="headline font-weight-bold"> 
                  Sistem Pakar Pertanian
                </h3>
                <v-spacer></v-spacer>
                <!-- <v-flex>
                  <v-img class="ml-3" contain height="48px" position="center right" src="@/assets/img/mardi-logo.png"></v-img>
                </v-flex> -->
              </v-layout>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <!-- <p>Sign in with your username and password:</p> -->
              <v-form>
                <v-text-field
                  label="Email"
                  append-icon="mdi-email-outline"
                  outlined
                  v-model="email"
                ></v-text-field>
                <v-text-field
                  label="Password"
                  append-icon="mdi-lock-outline"
                  outlined
                  type="password"
                  v-model="passwordLogin"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions :class="{ 'pa-3': $vuetify.breakpoint.smAndUp }">
              <!-- <v-btn color="primary" text>
                <v-icon left>mdi-lock-alert-outline</v-icon>
                Forgot password?
              </v-btn> -->
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="login()">
                <v-icon left>mdi-login-variant</v-icon>
                Login
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

  </div>
</template>

<script>
// -- import something here --

export default {

  data: () => ({
    email: "",
    passwordLogin: ""
  }),

  mounted() {
    
  },

  methods: {
    login(){
      this.$store.dispatch('signUserIn', { EMAIL: this.email, PASSWORD: this.passwordLogin });
    },
  },

};
</script>

<style lang="scss">
@import '~scss/main';

.background {
  background-image: url("~@/assets/img/padi.jpg") !important;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.headline{
  color: #29648A;
  word-break: break-word;
}
</style>


